import React, { useEffect } from "react";
import DocumentTitle from "react-document-title";

export const WirSind = () => {

  useEffect(() => {
    // scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);
  return (
    <DocumentTitle title="Momentos Spanisch lernen: Deine Spanischlehrer">
      <>
        <div className="container-fluid background-wir">
          <div className="row align-items-start wirsind">
            <div className="col-md-7 col-sm-7 col-xm-7">
              <img className="wirsind-img" src="nosotros2.jpg" />
            </div>
            <div className="col-md-5 col-sm-5 col-xm-5 text-center wir-text">
              <h4>Kompetenz entstand durch <br />20-jährige Erfahrung</h4>
            </div>
          </div>
        </div>
        <div className="content row">
        </div>

        <div className='inhalt'>
          <div className="content row">
            <section className="content-section col-md-6 mx-auto col-sm-12">
              <div
                data-aos="fade-up"
              >
                <div className="wirsind-narracion">
                  <h4>Kompetenz entstand durch <br />20-jährige Erfahrung</h4>
                  <p>                  Hola, ich bin Ihre Spanischlehrerin.</p>
                  <p><strong>Ich bin Eugenia Fernández</strong> und komme aus Barcelona.</p>

                  <p>Ich habe meine Sprache schon immer bewusst geliebt.</p>

                  <p>In Deutschland habe ich verschiedene Seminare für "Spanisch als Fremdsprache" besucht<br /> und bin über die Jahre sehr autodidaktisch gewachsen.</p>

                  <p>Ich unterrichte sehr engagiert und menschenorientiert.</p>

                  <p>Ich verstehe meine Arbeit als Spanisch Lehrerin unter dem Motto:<br />

                    "Man kann nicht „nicht lernen“".</p>

                  <p>Jeder von uns lernt mit normalen, langsamen oder schnellen Schritten, aber jeder von uns lernt immer wieder weiter.</p>
                  <br />


                  <p><strong>Ich bin Ralf Klein</strong> und komme aus Deutschland.</p>

                  <p>1995 stieg ich aus dem Zug in Barcelona.</p>

                  <p>2001 kam ich mit meinem Lizenziat "Spanisch Philologie, Spanische Sprach - und Literaturwissenschaft zurück.</p>

                  <p>Ich habe Spanisch unterrichtet und <strong>„Momentos - Spanisch lernen“</strong> gegründet, wo ich alles Organisatorisches erledige.</p>

                  <p>Ich liebe Kulturen und ihre Menschen.</p>

                  <p>Seit 2015 unterrichte ich intensiv Deutsch.</p>

                  <p>Ralfs Deutschkurse gibt es von Alphabetisierung bis C1</p>
                  <p>Kontakt: 0162 4569459</p>
                </div>
              </div>
            </section>

          </div>
        </div>
      </>
    </DocumentTitle>
  )
}

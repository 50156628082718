import React from "react";
import DocumentTitle from "react-document-title";
import { Link } from "react-router-dom";

export const OnlineAK = () => {
  return (
    <DocumentTitle title="Momentos Spanisch lernen: Online Anfaengerkurs">
      <>
        <div className="row">
          <div className="col-md-12 background-info">
            <div className="col-md-6 e-info">
              <h2>Online Anf&auml;ngerkurs<br />
                ab April
              </h2>
              <h3>Wann?</h3>
              <p>Donnerstags<br />
                15:30 - 17:00</p>

              <h3>10 Termine</h3>
              <p>Auf Anfrage</p>
              <h3>Kursgebühr</h3>
              <p>199&euro; ab 4 Teilnehmer</p>
              <h3>Kursbuch</h3>
              <p>Perspectivas Contigo / Cornelsen</p>
              <div className="mano"><i className="bi bi-hand-index-thumb"></i></div>
              <Link to="/kontakt" className="mano-kf">Zum Kontaktformular</ Link>
            </div>
          </div>
        </div>
      </>
    </DocumentTitle>
  )
}

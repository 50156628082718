import React from "react";
import DocumentTitle from "react-document-title";
import { Link } from "react-router-dom";

export const B2abLektion3 = () => {
  return (
    <DocumentTitle title="Momentos Spanisch lernen: Kurs Niveau B2 ab Lektion 3">
      <>
        <div className="row">
          <div className="col-md-12 background-info">
            <div className="col-md-6 e-info">
              <h2>Kurs Niveau B2<br />
                4.Buch, ab Lektion 5
              </h2>
              <p>Wir lernen immer weiter und wiederholen auch gelernte Verbzeiten.<br />
                Der Unterricht ist auf Konversation aufgebaut.
              </p>
              <h3>Wann?</h3>
              <p>Mittwochs<br />
                19:45 - 21:15</p>

              <h3>Termine</h3>
              <p>Der Kurs l&auml;uft fortlaufend, fragen Sie uns und steigen Sie jederzeit in die Gruppe ein.
              </p>
              <h3>Kursgebühr</h3>
              <p>260&euro; / 10 Termine</p>
              <h3>Kursbuch</h3>
              <p>Impresiones B2 / Hueber</p>
              <div className="mano"><i className="bi bi-hand-index-thumb"></i></div>
              <Link to="/kontakt" className="mano-kf">Zum Kontaktformular</ Link>
            </div>
          </div>
        </div>
      </>
    </DocumentTitle>
  )
}

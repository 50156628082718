import React, { useEffect } from "react";
import DocumentTitle from "react-document-title";
import { Link } from "react-router-dom";

export const Onlinekurse = () => {

  useEffect(() => {
    // scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <DocumentTitle title="Momentos Spanisch lernen: Onlinekurse">
      <>
        <img className="background-image background-image-ou" src="onlineunterricht.jpg" />

        <div className="content row">
        </div>

        <div className='inhalt onlinekurse'>
          <div className="content row">
            <section className="content-section col-md-6 mx-auto col-sm-12">
              <div
                data-aos="fade-up"
              >
                <div className="primera-frase">
                  <h4>
                    Sie bestimmen den Ort…<br />
                    und sparen dabei sehr viel Zeit.
                  </h4>
                </div>
              </div>
            </section>

            <section className="content-section col-md-6 mx-auto col-sm-12">
              <div
                data-aos="fade-left"
              >
                <div className="circulos">
                  <div className="circulo c3">
                    <Link to="/online-ak-info">
                      ONLINE<br />
                      ANFÄNGERKURS<br />
                      ab April
                    </Link>
                  </div>
                  <div className="circulo c2">
                    <Link to="/online-eu-info">
                      ONLINE<br />
                      EINZEL-<br />UNTERRICHT
                    </Link>
                  </div>
                </div>
              </div>
            </section>
          </div>

        </div>
      </>
    </DocumentTitle>
  )
}

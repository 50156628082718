import React from "react";
import { Link } from "react-router-dom";

export const Nachhilfe = () => {
  return (
    <>
      <div className="row">
        <div className="col-md-12 background-info">
          <div className="col-md-6 e-info">
            <h2>Nachhilfe Sch&uuml;ler*innen
            </h2>
            <p>
              Du lernst Spanisch, aber kommst allein nicht klar? Wir versuchen zusammen eine Klarheit zu schaffen.
              Oder vielleicht möchtest du dich für etwas Konkretes vorbereiten oder ein Thema vertiefen?
            </p>
            <p>
              Wir begleiten dich in deiner Entwicklung in der spanischen Sprache. Schreib und erkläre uns, was du brauchst.
            </p>
            <p>
              Wir freuen uns dich zu begleiten und dir zu helfen.
            </p>
            <h3>Geb&uuml;hr Pr&auml;senz- oder Online-Unterricht</h3>
            <p>60 Minuten: 45&euro;</p>
            <h3>Unsere Leitlinien</h3>
            <ul>
              <li>Du buchst einen Spanischkurs mit 5 oder 10 Terminen, jeweils 60 Minuten.</li>
              <li>Wir legen zusammen die Termine fest.</li>
              <li>Diese Termine sind dann für dich reserviert.</li>
              <li>Wenn du mal nicht an der Spanischstunde teilnehmen kannst, sag uns bitte 24 Stunden vorher ab,
                ansonsten müssen wir dir die nicht rechtzeitig abgesagte Stunde berechnen.</li>
            </ul>
            <div className="mano"><i className="bi bi-hand-index-thumb"></i></div>
            <Link to="/kontakt" className="mano-kf">Zum Kontaktformular</ Link>
          </div>
        </div>
      </div>
    </>
  )
}

import React, { useState } from "react";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { NavLink } from 'react-router-dom';


const Navigation = () => {

  const [active, setActive] = useState('active');

  return (

    <Navbar bg="light" expand="lg" collapseOnSelect>
      <Container fluid>
        <Navbar.Brand href="/">
          <div><img src="logo.png" /></div>
          <div className="logo-test">momentos<br />Spanisch lernen</div>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            {/* https://stackoverflow.com/questions/69464125/react-bootstrap-nav-link-only-showing-as-active-on-second-click */}
            <Nav.Link as={NavLink} to="/home" id="scrollTop" className={active ? "active" : null} eventKey="home">Home</Nav.Link>
            <Nav.Link as={NavLink} to="/kurse" onClick={() => setActive(null)} eventKey="kurse">Kurse</Nav.Link>
            <Nav.Link as={NavLink} to="/onlinekurse" onClick={() => setActive(null)} eventKey="onlinekurse">Onlinekurse</Nav.Link>
            <Nav.Link as={NavLink} to="/einzelunterricht" onClick={() => setActive(null)} eventKey="einzelunterricht">Einzelunterricht</Nav.Link>
            <Nav.Link as={NavLink} to="/wir-sind" onClick={() => setActive(null)} eventKey="wir-sind">Wir sind...</Nav.Link>
            <Nav.Link as={NavLink} to="/kontakt" onClick={() => setActive(null)} eventKey="kontakt">Kontakt</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Navigation;

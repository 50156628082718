import React from "react";
import DocumentTitle from "react-document-title";
import { Link } from "react-router-dom";

export const OnlineEU = () => {
  return (
    <DocumentTitle title="Momentos Spanisch lernen: Online Einzelunterricht">
      <>
        <div className="row">
          <div className="col-md-12 background-info">
            <div className="col-md-6 e-info">
              <h2>Online Einzelunterricht
              </h2>
              <p>
                Willkommen zum Online
                Einzelunterricht,
                ein neues Konzept von Bildung. Das Lernbuch ist immer noch der Leitfaden, aber die verschiedenen Tools im Computer bringen Farbe und sehr viel Abwechslung und Interaktion in den Unterricht. Der Online-Unterricht wird als sehr intensiv und effizient erlebt.
              </p>
              <p>
                Wir stellen uns auf Ihre individuellen Wünsche und Bedürfnisse ein und begleiten Sie auf Ihrer persönlichen Spanischentwicklung.
              </p>
              <h3>Kosten Einzelunterricht / 60 Min.</h3>
              <p>1 Person: 57&euro;</p>
              <p>2 Personen: 40&euro; pro Person</p>
              <h3>Unsere Leitlinien</h3>
              <p>
                Sie buchen einen Spanischkurs mit 5 oder 10 Terminen, jeweils 60 Minuten.
                Wir legen zusammen die Termine fest.
                Diese Termine sind dann für Sie reserviert.
                Wenn Sie mal nicht an der Spanischstunde teilnehmen können, sagen Sie bitte 24 Stunden vorher ab, ansonsten müssen wir Ihnen die nicht rechtzeitig abgesagte Stunde berechnen.
              </p>
              <div className="mano"><i className="bi bi-hand-index-thumb"></i></div>
              <Link to="/kontakt" className="mano-kf">Zum Kontaktformular</ Link>
            </div>
          </div>
        </div>
      </>
    </DocumentTitle>
  )
}

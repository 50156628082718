import React from "react";
import DocumentTitle from "react-document-title";
import { Link } from "react-router-dom";

export const B1abLektion2 = () => {
  return (
    <DocumentTitle title="Momentos Spanisch lernen: Kurs Niveau B1 ab Lektion 2">
      <>
        <div className="row">
          <div className="col-md-12 background-info">
            <div className="col-md-6 e-info">
              <h2>Kurs Niveau B1<br />
                3.Buch, ab Lektion 4
              </h2>
              <p>Wir vertiefen das Subjuntivo und wiederholen immer wieder alle anderen gelernten Verbzeiten</p>
              <h3>Wann?</h3>
              <p>Montags<br />
                19:00 - 20:30</p>

              <h3>Termine</h3>
              <p>Der Kurs l&auml;uft fortlaufend, fragen Sie uns und steigen Sie jederzeit in die Gruppe ein.
              </p>
              <h3>Kursgebühr</h3>
              <p>260&euro; / 10 Termine</p>
              <h3>Kursbuch</h3>
              <p>El curso en vivo B1 / Klett</p>
              <div className="mano"><i className="bi bi-hand-index-thumb"></i></div>
              <Link to="/kontakt" className="mano-kf">Zum Kontaktformular</ Link>
            </div>
          </div>
        </div>
      </>
    </DocumentTitle>
  )
}

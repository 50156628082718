import React, { useEffect } from "react";
import DocumentTitle from "react-document-title";
import { Link } from "react-router-dom";

export const Kurse = () => {

  useEffect(() => {
    // scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);
  return (
    <DocumentTitle title="Momentos Spanisch lernen: Kurse">
      <>
        <img className="background-image-kurse" src="kurse.jpg" />

        <div className="content row">
        </div>

        <div className='inhalt kurse content row'>
          <section className="content-section col-md-6 mx-auto col-sm-12">
            <div
              data-aos="fade-up"
            >
              <div className="primera-frase">
                <h4>Unser Unterricht ist lebendig und individuell.<br /><br />
                  Sie lernen in einer Gruppe, werden sich gegenseitig motivieren und genießen den sozialen Kontakt.</h4>
              </div>
            </div>
          </section>
          <section className="content-section col-md-6 mx-auto  section2">
            <div
              data-aos="fade-left"
            >
              <div className="circulos">
                <div className="circulo c1">
                  <Link to="/anfaengerkurs-info">ANFÄNGERKURS<br />
                    ab dem 9.04
                  </Link>
                </div>
                <div className="circulo c2">
                  <Link to="/a1-ab-lektion6-info">A1<br />
                    ab Lektion 9<br />
                    1.Buch
                  </Link>

                </div>
                <div className="circulo c2">
                  <Link to="/a1-ab-lektion8-info">A1<br />
                    ab Lektion 10<br />
                    1.Buch
                  </Link>

                </div>
              </div>
            </div>
            <div
              data-aos="fade-left"
            >
              <div className="circulos circulos2">
                <div className="circulo c2">
                  <Link to="/b1-ab-lektion2-info">B1<br />
                    ab Lektion 4<br />
                    3.Buch
                  </Link>
                </div>
                <div className="circulo c2">
                  <Link to="/b1-ab-lektion4-info">B1<br />
                    ab Lektion 5<br />
                    3.Buch
                  </Link>

                </div>
                <div className="circulo c2">
                  <Link to="/b2-ab-lektion3-info">B2<br />
                    ab Lektion 5<br />
                    4.Buch
                  </Link>

                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    </DocumentTitle>
  )
}

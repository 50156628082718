import React, { useEffect, useRef } from 'react';
import emailjs from '@emailjs/browser';
import DocumentTitle from "react-document-title";

export const ContactUs = () => {


  useEffect(() => {
    // scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_fvogp6d', 'template_8m1wjdi', form.current, 'VaNdmFiqeO0C_yK7Q')
      .then((result) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });
    e.target.reset()
  };

  return (
    <DocumentTitle title="Momentos Spanisch lernen: Kontakt">

      <>
        <img className="background-image bg-img-kontakt" src="kontakt.jpg" />

        <div className="content row">
          <section className="content-section col-md-9 col-sm-7">
          </section>
        </div>

        <div className='inhalt inhalt-kontakt'>


          <div className="content row">
            <section className="content-section col-md-template_8m1wjdi6 mx-auto col-sm-12">
              <div
                data-aos="fade-up"
              >
                <div className="primera-frase-kontakt">
                  <h4>Wir freuen uns, Sie kennenzulernen!</h4>
                </div>
              </div>
              <div className="location-wrapper">
                <div className="mail-tel">
                  <p><i className="bi bi-geo-alt-fill"></i></p>
                  <p>Momentos</p>
                  <p>Hartheimerstr. 12, 1.OG links</p>
                  <p className="gewerbe">79427 Gewerbepark Eschbach</p>
                </div>
              </div>
            </section>
            <section className="content-section col-md-6 mx-auto col-sm-12">
              <div
                data-aos="fade-up"
              >
                <div className="primera-frase-kontakt">
                  <h4>Schreiben Sie uns einfach</h4>
                </div>
              </div>
              <form className='contact-form' ref={form} onSubmit={sendEmail}>
                <label htmlFor="name">Name</label>
                <input type="text" name="user_name" />
                <label htmlFor="email">Email</label>
                <input type="email" name="user_email" />
                <label htmlFor="message">Message</label>
                <textarea name="message" />
                <input type="submit" value="Send" />
              </form>
            </section>
            <section className="content-section col-md-7 mx-auto col-sm-12">
              <div
                data-aos="fade-up"
              >
                <div className="primera-frase-kontakt">
                  <h4>Oder rufen Sie uns an oder schreiben Sie uns eine E-mail</h4>
                </div>
              </div>
              <div className="location-wrapper">
                <div className="mail-tel">
                  <p><i className="bi bi-envelope-at-fill"></i> info@momentos-spanisch.de</p>
                  <p><i className="bi bi-telephone-fill"></i> 015751300099</p>
                  <p className="tel2"> 0162 4569459</p>
                </div>
              </div>
            </section>
          </div>
        </div>
      </>
    </DocumentTitle>
  );
};


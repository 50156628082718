import React, { useEffect } from "react";
import DocumentTitle from "react-document-title";
import { Link } from "react-router-dom";

export const EinzelunterrichtInfo = () => {
  useEffect(() => {
    // scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);
  return (
    <DocumentTitle title="Momentos Spanisch lernen: Einzelunterricht">
      <>
        <div className="row">
          <div className="col-md-12 background-info">
            <div className="col-md-6 e-info">
              <h2>Willkommen zum Einzelunterricht</h2>
              <p>Wir stellen uns auf Ihre individuellen Wünsche und Bedürfnisse ein.<br />
                Wir begleiten Sie auf Ihrer persönlichen Spanischentwicklung.</p>

              <h3>Kosten Einzelunterricht/ 60 Min</h3>
              1 Person: 57€<br />
              2 Personen: 39€ pro Person

              <h3>Unsere Leitlinien</h3>
              <ul>
                <li>Sie buchen einen Spanischkurs mit 5 oder 10 Terminen, jeweils 60 Minuten.</li>
                <li>Wir legen zusammen die Termine fest.</li>
                <li>Diese Termine sind dann für Sie reserviert.</li>
                <li>
                  Wenn Sie mal nicht an der Spanischstunde teilnehmen können, sagen Sie bitte
                  24 Stunden vorher ab, ansonsten müssen wir Ihnen die nicht rechtzeitig abgesagte
                  Stunde berechnen.
                </li>
              </ul>
              <div className="mano"><i className="bi bi-hand-index-thumb"></i></div>
              <Link to="/kontakt" className="mano-kf">Zum Kontaktformular</ Link>
            </div>
          </div>
        </div>
      </>
    </DocumentTitle>
  )
}

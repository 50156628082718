
import React from "react";

export const Error404 = () => {
  return (

    <>
      <div className="content404 row">
        <section className="content-section col-md-9 col-sm-7">
        </section>
      </div>
      <div>
        <h1>Error404</h1>
        <p>Seite nicht gefunden</p>
      </div>
    </>
  )
}

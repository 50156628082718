import React from "react";
import './footer.css'

export const Footer = () => {
  return (
    <div className="container-fluid">
      <div className="row">
        <footer className="footer col-12 col-md-12">
          Copyright &copy;  All Rights Reserved
        </footer>
      </div>
    </div>
  )
}

import React, { useEffect } from "react";
import DocumentTitle from "react-document-title";

export const Home = () => {

  useEffect(() => {
    // scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);
  return (
    <DocumentTitle title="Momentos Spanisch lernen">
      <>

        <img className="background-image col-12" src="hintergrund.png" />
        <div className="content row">
        </div>

        <div className="container-fluid">
          <div className='inhalt inhalt-kurse content row'>
            <section className="content-section col-12 col-md-8 mx-auto">
              <div
                data-aos="fade-up"
              >
                <div className="primera-frase">
                  <h4>Sie lernen Spanisch, indem Sie von Anfang an die Sprache anwenden und Mut haben Fehler zu machen.</h4>
                </div>
              </div>
            </section>
            <div className="row">
              <img className="col col-md-10 mx-auto img-home" src="home.jpg" />
              <p className="instagram text-center">
                <a target="_blank" title="Momentos Spanisch Lernen in Instagram"
                  href="https://www.instagram.com/momentos_spanisch_schule/?hl=de"><i className="bi bi-instagram" /></a>
              </p>
            </div>

          </div>
        </div>
      </>
    </DocumentTitle>
  )
}

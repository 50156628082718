import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import DocumentTitle from "react-document-title";

export const Einzelunterricht = () => {

  useEffect(() => {
    // scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);
  return (
    <DocumentTitle title="Momentos Spanisch lernen: Einzelunterricht">
      <>
        <img className="background-image img-eu" src="einzelunterricht.jpg" />

        <div className="content row">
          <section className="content-section col-md-9 col-sm-7">
          </section>
        </div>

        <div className='inhalt inhalt-eu'>
          <div className="content row">
            <section className="content-section col-md-6 mx-auto col-sm-12">
              <div
                data-aos="fade-up"
              >
                <div className="primera-frase">
                  <h4>Sie lernen gezielt und wir begleiten Sie kompetent auf Ihrem Weg.</h4>
                </div>
              </div>
            </section>
            <section className="content-section col-md-6 mx-auto col-sm-12">
              <div
                data-aos="fade-left"
              >
                <div className="circulos">
                  <div className="circulo c1">
                    <Link to="/einzelunterricht-info">
                      MEHR INFORMATION
                    </Link>
                  </div>
                  <div className="circulo c1">
                    <Link to="/nachhilfe-info">
                      NACHHILFE< br />SCH&Uuml;LER*INNEN
                    </Link>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </>
    </DocumentTitle>
  )
}

import React from "react";
import { Routes, Route, BrowserRouter } from 'react-router-dom'
import { Home } from '../components/Home'
import { Kurse } from '../components/Kurse'
import { Error404 } from '../components/Error404'
import { Footer } from '../components/footer/Footer'
import Navigation from '../components/navbar/Navigation.jsx'
import { Onlinekurse } from "../components/OnlineKurse";
import { Einzelunterricht } from "../components/Einzelunterricht";
import { WirSind } from "../components/WirSind";
import { EinzelunterrichtInfo } from "../components/EinzelunterrichtInfo";
import { ContactUs } from "../components/ContactUs";
import { AnfaengerKurs } from "../components/AnfaengerKurs";
import { A1abLektion6 } from "../components/A1abLektion6";
import { A1abLektion8 } from "../components/A1abLektion8";
import { B1abLektion2 } from "../components/B1abLektion2";
import { B1abLektion4 } from "../components/B1abLektion4";
import { B2abLektion3 } from "../components/B2abLektion3";
import { OnlineAK } from "../components/OnlineAK";
import { OnlineEU } from "../components/OnlineEU";
import { Nachhilfe } from "../components/Nachhilfe";
import ScrollToTop from "../components/ScrollToTop";

export const RouterPrincipal = () => {

  return (
    <BrowserRouter>
      <ScrollToTop />
      {/* HEADER Y NAVEGACION */}
      <Navigation />

      {/* CONTENIDO CENTRAL */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/kurse" element={<Kurse />} />
        <Route path="/onlinekurse" element={<Onlinekurse />} />
        <Route path="/einzelunterricht" element={<Einzelunterricht />} />
        <Route path="/wir-sind" element={<WirSind />} />
        <Route path="/kontakt" element={<ContactUs />} />
        <Route path="/einzelunterricht-info" element={<EinzelunterrichtInfo />} />
        <Route path="/nachhilfe-info" element={<Nachhilfe />} />
        <Route path="/anfaengerkurs-info" element={<AnfaengerKurs />} />
        <Route path="/a1-ab-lektion6-info" element={<A1abLektion6 />} />
        <Route path="/a1-ab-lektion8-info" element={<A1abLektion8 />} />
        <Route path="/b1-ab-lektion2-info" element={<B1abLektion2 />} />
        <Route path="/b1-ab-lektion4-info" element={<B1abLektion4 />} />
        <Route path="/b2-ab-lektion3-info" element={<B2abLektion3 />} />
        <Route path="/online-ak-info" element={<OnlineAK />} />
        <Route path="/online-eu-info" element={<OnlineEU />} />

        <Route path="*" element={<Error404 />} />
      </Routes>

      {/* FOOTER */}
      <Footer />
    </BrowserRouter>
  )
}

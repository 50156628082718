import React from "react";
import DocumentTitle from "react-document-title";
import { Link } from "react-router-dom";

export const AnfaengerKurs = () => {
  return (
    <DocumentTitle title="Momentos Spanisch lernen: Anfaengerkurs">
      <>
        <div className="row">
          <div className="row">
            <div className="col-md-12 background-info">
              <div className="col-md-6 e-info">
                <h2>Anf&auml;ngerkurs<br />
                  1.Buch, ab Lektion 1
                </h2>
                <h3>Wann?</h3>
                <p>Ab dem 9.4, dienstags<br />
                  19:00 - 20:30</p>

                <h3>10 Termine</h3>
                <p>9.4, 16.4, 30.4, 7.5, 14.5<br />
                  28.5, 4.6, 11.6, 18.6, 25.6
                </p>
                <h3>Kursgebühr</h3>
                <p>199&euro; ab 4 Teilnehmer</p>
                <h3>Kursbuch</h3>
                <p>Perspectivas Contigo / Cornelsen</p>
                <div className="mano"><i className="bi bi-hand-index-thumb"></i></div>
                <Link to="/kontakt" className="mano-kf">Zum Kontaktformular</ Link>
              </div>
            </div>
          </div>
        </div>
      </>
    </DocumentTitle>
  )
}

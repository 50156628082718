import React from "react";
import DocumentTitle from "react-document-title";
import { Link } from "react-router-dom";

export const A1abLektion6 = () => {
  return (
    <DocumentTitle title="Momentos Spanisch lernen: Kurs Niveau A1 ab Lektion 6">
      <>
        <div className="container-fluid">
          <div className="row">
            <div className="col col-md-12 background-info">
              <div className="col-md-6 e-info">
                <h2>Kurs Niveau A1<br />
                  1.Buch, ab Lektion 9
                </h2>
                <p>Wir kennen das Präsens, unregelm&auml;&szlig;ige Verben und die nahe Zukunft.</p>
                <h3>Wann?</h3>
                <p>Donnerstags<br />
                  19:00 - 20:30</p>

                <h3>Termine</h3>
                <p>Der Kurs l&auml;uft fortlaufend, fragen Sie uns und steigen Sie jederzeit in die Gruppe ein.
                </p>
                <h3>Kursgebühr</h3>
                <p>199&euro; / 10 Termine</p>
                <h3>Kursbuch</h3>
                <p>Perspectivas Contigo / Cornelsen</p>
                <div className="mano"><i className="bi bi-hand-index-thumb"></i></div>
                <Link to="/kontakt" className="mano-kf">Zum Kontaktformular</ Link>
              </div>
            </div>
          </div>
        </div>
      </>
    </DocumentTitle>
  )
}
